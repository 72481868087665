.vote-cards {
	width: 100%;
	z-index: 2;

	&__list {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: 0 60px;
		margin-top: 45px;

		@include RWD(mobile) {
			padding: 0 25px;
		}
	}

	&__item {
		display: flex;
		flex-direction: column;
		position: relative;
		height: auto;
		width: 45%;
		border-radius: 6px;
		background: $green-ess-gradient;
		box-shadow: 0 2px 6px 0 #DADADA;
		margin-bottom: 40px;
		padding: 15px;

		&[data-key="innov"]{
			background: $blue-ess-gradient;
		}

		&[data-key="valess"]{
			background: $orange-ess-gradient;
		}

		&[data-key="pess"]{
			background: $orange-ess-gradient;
		}

		&.one {
			order: -1;
		}

		@include RWD(mobile) {
			width: 100%;
		}
	}

	&__visu {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		width: 100%;
		height: 140px;
		min-height: 140px;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		border-radius: 4px;
	}

	&__link {
		align-self: center;
		margin-bottom: 10px;
	}

	&__text {
		display: flex;
		flex-direction: column;
		height: 100%;

		&__title {
			font-size: 1.3rem;
			color: $text-color;
			margin-top: 10px;
			font-weight: bold;
		}

		&__location {
			font-size: 1.3rem;
			color: $text-color;
			margin-top: 20px;

			&:before {
				content: '';
				position: relative;
				display: inline-block;
				width: 20px;
				height: 20px;
				flex-shrink: 0;
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
				background-image: url(svg-bg-uri($svg-location, $blue-ess));
				margin-right: 5px;
			}
		}
	}

	&__btn {
		align-self: center;
		margin-bottom: 10px;
		margin-top: 20px;

		&.moved{
			margin-top: 0;
			margin-bottom: 0;
			background-color: #f9f9f9;
		}

		@include RWD(tablet){
			min-height: 40px;
		}
	}
}