.project {
	display: none;
	justify-content: center;
	align-items: center;
	z-index: 100;
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	background: rgba($color: #000, $alpha: 0.7);

	@include RWD(mobile) {
		background-color: rgba(25, 42, 94, 0.9);
	}

	&.open {
		display: flex;
	}

	&__box {
		z-index: 2;
		display: block;
		position: relative;
		width: 100%;
		max-width: 760px;
		height: 75%;
		overflow-y: auto;
		padding: 50px 80px;
		background-color: #fff;
		border-radius: 10px;
		animation: popinIntro 0.5s;

		@include RWD(tablet) {
			max-width: 80%;
			height: 80%;
		}

		@include RWD(mobile) {
			max-width: 85%;
			padding-top: 50px;
			padding-bottom: 30px;
			padding-left: 20px;
			padding-right: 20px;
			overflow-y: scroll;
			flex-direction: column;
		}
	}

	//Actions

	&__close {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 40px;
		height: 40px;
		background-color: $blue-ess;
		border-radius: 50%;
		box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.2);

		@include RWD(mobile) {
			width: 30px;
			height: 30px;
		}

		&:before {
			content: '';
			display: inline-block;
			width: 20px;
			height: 20px;
			flex-shrink: 0;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url(svg-bg-uri($svg-close, #fff));

			@include RWD(mobile) {
				width: 10px;
				height: 10px;
			}
		}

		&__wrapper {
			width: 100%;
			position: fixed;
			display: flex;
			justify-content: flex-end;

			@include RWD(desktop) {
				max-width: 640px;
			}

			@include RWD(tablet) {
				max-width: calc(80% - 105px)
			}

			@include RWD(mobile) {
				max-width: calc(80% - 35px);
			}
		}
	}

	&__share {
		position: absolute;
		right: 80px;
		top: 45px;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 55px;
		height: 55px;
		background: $green-ess-gradient;
		border-radius: 50%;
		box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.2);

		@include RWD(mobile) {
			width: 38px;
			height: 38px;
			right: inherit;
			top: 25px;
			left: 20px;
		}

		&:before {
			content: '';
			display: inline-block;
			width: 20px;
			height: 20px;
			flex-shrink: 0;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url(svg-bg-uri($svg-share, #000000));
		}
	}

	//Text

	&__title {
		color: $blue-ess;
		font-size: 43px;
		font-weight: bold;
		line-height: 50px;
		text-align: center;
		margin-bottom: 20px;

		@include RWD(tablet) {
			text-align: left;
			font-size: 35px;
		}

		@include RWD(mobile) {
			font-size: 23px;
			line-height: 1.2em;
		}

		&--small {
			display: none;
			color: $blue-ess;
			font-weight: bold;
			font-size: 18px;
			text-align: left;
			margin-top: 50px;
			margin-bottom: 10px;

			@include RWD(mobile) {
				margin-top: 20px;
			}
		}
	}

	&__theme {
		color: $text-color;
		margin-bottom: 20px;
		display: flex;

		@include RWD(tablet) {
			flex-direction: column;
		}

		&__item {
			font-size: 1.3rem;
			margin-right: 15px;
			font-weight: bold;

			&:last-child {
				margin-right: 0;
			}

			&:after {
				display: none;
			}

			@include RWD(tablet) {
				font-size: 1.2rem;
				margin-bottom: 15px;
				align-self: flex-start;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		@include RWD(tablet) {
			font-size: 18px;
		}

		@include RWD(mobile) {
			font-size: 15px;
		}
	}

	&__category {
		color: $text-color;
		font-size: 21px;
		margin-bottom: 25px;

		@include RWD(tablet) {
			font-size: 18px;
		}

		@include RWD(mobile) {
			font-size: 15px;
		}
	}

	&__location {
		display: flex;
		align-items: center;
		color: $text-color;
		font-size: 13px;
		margin-bottom: 20px;

		&:empty {
			display: none;
		}

		&:before {
			content: '';
			display: inline-block;
			width: 40px;
			height: 40px;
			flex-shrink: 0;
			background-size: 17px;
			background-position: center;
			background-repeat: no-repeat;
			background-color: $green-ess;
			border-radius: 50%;
			background-image: url(svg-bg-uri($svg-location, #000000));
			margin-right: 10px;
		}
	}

	&__description {
		color: $text-color;
		font-size: 13px;
		margin-bottom: 20px;
		margin-top: 50px;

		@include RWD(tablet) {
			margin-top: 20px;
		}
	}

	&__video{
		display: flex;
		text-decoration: none;
		color: $text-color;
		margin-bottom: 70px;

		@include RWD(tablet) {
			margin-bottom: 40px;
		}

		@include RWD(mobile) {
			margin-bottom: 30px;
		}

		&__text {
			color: $text-color;
			font-weight: bold;
			margin-right: 3px;
		}

		&__link{
			text-decoration: underline;
		}

		&[href=''] {
			display: none;
		}
	}

	//Compteur de votes

	&__votes {
		display: flex;

		@include RWD(mobile) {
			margin-bottom: 25px;
		}

		&__text {
			color: $text-color;
			font-size: 14px;
			max-width: 150px;
			margin-left: 20px;
		}

		&__icon {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 38px;
			width: 38px;
			border-radius: 50%;
			background-color: #FFFFFF;
			box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

			&:before {
				content: '';
				display: inline-block;
				width: 17px;
				height: 17px;
				flex-shrink: 0;
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
				background-image: url(svg-bg-uri($svg-heart, #D75A4A));
			}
		}
	}

	&__facebook {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 40px;
		height: 40px;
		min-height: 40px;
		border-radius: 50%;
		background: $green-ess-gradient;
		margin-bottom: 20px;

		&:before {
			content: '';
			display: inline-block;
			width: 20px;
			height: 20px;
			flex-shrink: 0;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url(svg-bg-uri($svg-facebook, #2C2C2C));
		}

		&[href=''] {
			display: none;
		}
	}

	&__website {
		display: flex;
		text-decoration: none;
		color: $text-color;
		margin-bottom: 20px;

		@include RWD(mobile){
			flex-wrap: wrap;
		}

		&__text {
			color: $text-color;
			font-weight: bold;
			margin-right: 3px;
		}

		&__link{
			text-decoration: underline;
		}

		&[href=''] {
			display: none;
		}
	}

	&__vote-btn {
		box-shadow: 0 1px 6px 0 #DADADA;

		@include RWD(mobile) {
			margin-top: 20px;
		}
	}

	&__col {
		display: flex;
		align-items: center;
		justify-content: space-between;

		@include RWD(mobile) {
			flex-direction: column;
		}
	}
}

// Page int

.video-link{
	margin-top: 20px;
}