.galerie {
	width: 100%;
	overflow: hidden;
	position: relative;
	min-height: 400px;
	height: 400px;
	border-radius: 10px;

	@include RWD(tablet){
		z-index: -1;
		min-height: 300px;
		height: 300px;
	}

	@include RWD(mobile) {
		min-height: 250px;
		height: 250px;
	}

	ul {
		height: 100%;
		position: relative;
		transition-duration: 0.2s;
		transform: translate3d(0px, 0px, 0px);

		li {
			float: left;
			list-style: none;
			height: 100%;
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
			position: absolute;
			display: flex;
			align-items: center;
			justify-content: center;

			span {
				position: absolute;
				right: 0;
				bottom: 0;
				padding: 10px 20px;
				background-color: rgba(255, 255, 255, 0.6);
				font-size: 16px;
				font-size: 1.6rem;
			}
		}
	}

	.galerie-prev,
	.galerie-next {
		position: absolute;
		width: 50px;
		height: 50px;
		top: calc(50% - 25px);
		cursor: pointer;
		background-color: #fff;
		border-radius: 50px;
		background-size: 50%;
		background-repeat: no-repeat;
		background-position: center;
		z-index: 2;

		&.disabled {
			display: none;
		}

		&:hover {
			opacity: 0.5;
		}
	}

	.galerie-prev {
		background-image: url(svg-bg-uri($svg-chevron-left, #5d5d5d));
		left: 10px;
	}

	.galerie-next {
		background-image: url(svg-bg-uri($svg-chevron-right, #5d5d5d));
		right: 10px;
	}
}