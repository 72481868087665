.search-projects {
	position: relative;
	border-radius: 4px;
	padding: 20px;
	background-color: #F9F9F9;
	box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.15);
	margin-top: 15px;
	margin-bottom: 40px;

	@include RWD(tablet) {
		margin-top: 40px;
	}

	&__wrapper {
		position: relative;
	}

	&__searchbar {
		display: flex;
		flex-direction: column;
		margin: 20px 0;

		&__label {
			margin-bottom: 10px;
			font-size: 1.3rem;
			color: $text-color;

			@include RWD(tablet) {
				font-size: 1rem;
				font-weight: bold;
				color: #212121;
			}
		}

		&__group {
			display: flex;
			position: relative;
		}

		&__input {
			width: 100%;
			border-style: none;
			padding: 15px 20px;
			border-radius: 17.5px;
			background-color: #FFFFFF;
			box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

			@include RWD(tablet) {
				background-color: #EFEFEF;
				box-shadow: none;
			}

			&::placeholder {
				color: #868585;
				font-size: 1rem;
				font-weight: 500;

				@include RWD(tablet) {
					color: #212121;
				}
			}
		}

		&__btn {
			position: absolute;
			right: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 50px;
			height: 100%;
			border-top-right-radius: 17.5px;
			border-bottom-right-radius: 17.5px;

			@include RWD(tablet) {
				background-color: #EFEFEF;
				color: #212121;
			}

			&:before {
				content: '';
				display: inline-block;
				width: 16px;
				height: 16px;
				flex-shrink: 0;
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
				background-image: url(svg-bg-uri($svg-search, #000000));
				transform: rotate(90deg);
			}

			&:after {
				content: '';
				position: absolute;
				left: 0;
				width: 2px;
				height: 80%;
				background-color: #f2f3f4;

				@include RWD(tablet) {
					display: none;
				}
			}
		}

		&__erase{
			position: relative;
			display: none;
			align-items: center;
			align-self: flex-end;
			color: $text-color;
			font-size: 1.2rem;
			margin-bottom: 10px;

			&:after{
				content: '';
				display: inline-block;
				position: relative;
				width: 7px;
				height: 7px;
				flex-shrink: 0;
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
				background-image: url( svg-bg-uri($svg-close, $text-color) );
				margin-left: 5px;
			}

			&.active{
				display: flex;
				animation: fadeIn 0.4s forwards;
			}
		}
	}

	&__categories {
		display: flex;
		flex-direction: column;

		&__content {
			display: none;
			padding-top: 20px;

			&.active {
				display: block;
			}
		}

		&__titre {
			margin-bottom: 10px;
			font-size: 1.3rem;
			color: $text-color;
			text-align: center;

			@include RWD(tablet) {
				font-size: 1rem;
				font-weight: bold;
				color: #212121;
				margin-bottom: 10px;
			}
		}

		&__list {
			@include RWD(tablet) {
				flex-wrap: wrap;
				display: flex;
			}
		}

		&__item {
			display: flex;
			align-items: center;
			font-size: 1.3rem;
			color: $text-color;
			margin-bottom: 10px;

			&:last-child {
				margin-bottom: 0;
			}

			@include RWD(tablet) {
				width: 33%;
			}

			@include RWD(mobile) {
				width: 100%;
			}

			input[type="checkbox"] {
				-webkit-appearance: none;
				-moz-appearance: none;
				-ms-appearance: none;
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 0;
				cursor: pointer;

				border-radius: 4px;
				height: 15px;
				width: 15px;
				background: #fff;
				border: 1px solid #ccc;
				transition: 0.3s all;
			}

			input[type="checkbox"]:checked {
				background: $green-ess-gradient;
				border: 1px solid $green-ess;
				margin: 0px;
				position: relative;
				transition: 0.3s all;

				&:before {
					content: '';
					display: inline-block;
					width: 8px;
					height: 8px;
					flex-shrink: 0;
					background-size: contain;
					background-position: center;
					background-repeat: no-repeat;
					background-image: url(svg-bg-uri($svg-check, #000000));
					transition: 0.3s all;
				}
			}

			label {
				margin-left: 10px;
				cursor: pointer;
			}

			div {
				display: flex;
				align-items: center;
				transition: 0.3s all;

				&:hover,
				&:focus {
					label {
						color: #000000;
						transition: 0.3s all;
					}

					input {
						border: 1px solid $green-ess;
						transition: 0.3s all;
					}
				}
			}
		}

		&__btn {
			display: flex;
			margin-top: 10px;
			margin: 0 auto;
			margin-top: 15px;
		}

		&__select {
			position: relative;
			border: 0;
			border-radius: 22.5px;
			background-color: #EFEFEF;
			padding: 15px;
			font-size: 10px;
			font-weight: 500;
			color: #212121;

			&:before {
				content: '';
				position: absolute;
				right: 5px;
				display: inline-block;
				width: 20px;
				height: 20px;
				flex-shrink: 0;
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
				background-image: url(svg-bg-uri($svg-arrow, #000));
				transform: rotate(90deg);
				z-index: 10;
			}

			option {
				font-size: 1rem;
				color: #212121;
				font-weight: 500;
			}

			@include RWD(desktop) {
				display: none;
			}
		}

		&__toggle {
			width: 35px;
			height: 35px;
			display: flex;
			justify-content: center;
			align-content: center;
			align-self: center;
			background-color: #fff;
			border-radius: 50%;
			box-shadow: 0 3px 4px 0 rgba(103, 103, 103, 0.5);
			background-image: url(svg-bg-uri($svg-chevron-down, #212121));
			background-size: 15px;
			background-position: center;
			background-repeat: no-repeat;
			cursor: pointer;
			transition: all 0.3s;

			&.active {
				transform: rotate(180deg);
				box-shadow: 0 3px 4px 0 rgba(103, 103, 103, 0.5);
				transition: all 0.3s;
			}
		}
	}

	/* clears the 'X' from Internet Explorer */
	input[type=search]::-ms-clear {
		display: none;
		width: 0;
		height: 0;
	}

	input[type=search]::-ms-reveal {
		display: none;
		width: 0;
		height: 0;
	}

	/* clears the 'X' from Chrome */
	input[type="search"]::-webkit-search-decoration,
	input[type="search"]::-webkit-search-cancel-button,
	input[type="search"]::-webkit-search-results-button,
	input[type="search"]::-webkit-search-results-decoration {
		display: none;
	}
}