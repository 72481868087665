.congrats {
    position: relative;
    width: 100%;
    max-width: 630px;
	border-radius: 6px;
	background-color: #FFFFFF;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.2);
    transform: translateY(-110px);
    padding: 50px;
    margin: 0 auto;
    z-index: 10;
    
    @include RWD(mobile){
        padding: 20px;
        background-color: #E8E9E8;
    }

	&__title {
        @include RWD(mobile){
            color: #212121;
        }
    }

	&__list {
        padding-left: 70px;
        
        @include RWD(mobile){
            padding-left: 20px;
        }
	}

	&__item {
		position: relative;
		font-size: 1.3rem;
        color: $text-color;
        margin-top: 40px;

        @include RWD(mobile){
            display: flex;
            flex-direction: column;
        }

		&:before {
			content: '';
			position: absolute;
			left: -20px;
			top: 3px;
			height: 10px;
			width: 10px;
            background-color: $green-ess;
            border-radius: 50%;
		}
    }

    &__return{
        margin-bottom: 10px;
    }
    
    @at-root {
        .page-title & {
            margin-bottom: 180px;
        }
    }
}