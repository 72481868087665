.btn {
	&-small {
		position: relative;
		display: flex;
		justify-content: center;
		padding: 8px 15px;
		border-radius: 25px;
		background-color: #F4F5F6;
		text-decoration: none;
		transition: all 0.3s;

		&:hover,
		&:focus {
			.btn-text{
				color: #000000;
			}

			&:after{
				width: 100%;
			}
		}

		&:after {
			content: '';
			position: absolute;
			transition: all 0.3s;
			width: 0%;
			height: 100%;
			top: 0;
			left: 0;
			background: #DBDCDD;
			border-radius: 15px;
		}

		.btn-text {
			font-family: $main-font;
			font-size: 1rem;
			font-weight: 500;
			color: #212121;
			line-height: 1em;
			z-index: 2;
		}

		.flexbox {
			z-index: 2;
		}

		//OTHER SKINS
		&.blue {
			background-color: $blue-ess;
			color: #fff;
		}
	}

	&-main {
		position: relative;
		display: flex;
		padding: 10px 20px;
		border-radius: 17px;
		background-color: #fff;
		cursor: pointer;

		.btn-text {
			font-family: $main-font;
			font-size: 1.4rem;
			font-weight: 500;
			color: #212121;
		}

		.flexbox {
			display: flex;
			align-items: center;
			z-index: 2;
		}

		.btn-icon {
			margin-left: 10px;
		}
	}

	&-heart {
		position: relative;
		display: flex;
		padding: 10px 20px;
		border-radius: 17px;
		background-color: #fff;
		cursor: pointer;
		min-height: 35px;

		@media only screen and (min-width: $break-large + "px") {
			&:hover {
				.btn-icon {
					background-position: right top;
				}
			}
		}

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			min-height: 30px;
		}

		.btn-text {
			font-family: $main-font;
			font-size: 1.3rem;
			font-weight: 500;
			color: #212121;
			margin-right: 20px;

			@media (max-width: 330px) {
				font-size: 1.2rem;
			}
		}

		.flexbox {
			display: flex;
			align-items: center;
		}

		.btn-icon {
			position: absolute;
			right: 0;
			background-image: url('https://abs.twimg.com/a/1474649078/img/animations/web_heart_animation.png');
			background-position: left top;
			background-repeat: no-repeat;
			background-size: 2900%;
			cursor: pointer;
			display: block;
			width: 50px;
			height: 50px;

			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				top: -10px;
			}

			&.on {
				animation: heartAnim 1s steps(28) forwards;
			}
		}
	}

	&-arrow {
		position: relative;
		z-index: 10;
		display: flex;
		padding: 10px 20px;
		border-radius: 17px;
		cursor: pointer;

		.btn-text {
			font-family: $main-font;
			font-size: 1.4rem;
			font-weight: 500;
			color: #212121;
			margin-left: 50px;
		}

		.flexbox {
			display: flex;
			align-items: center;
		}

		.btn-icon {
			content: '';
			position: absolute;
			display: inline-block;
			width: 30px;
			height: 30px;
			flex-shrink: 0;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url(svg-bg-uri($svg-arrow, #000000));
			transform: rotate(-180deg);
		}
	}
}