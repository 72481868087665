.vote-list {
	display: flex;
	flex-direction: column;
	border-radius: 6px;
	background-color: #F1F1F1;

	@include RWD(tablet) {
		position: relative;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			margin-left: calc(-50vw + 50%);
			width: 100vw;
			height: 100%;
			background-color: #F9FAFB;
		}
	}

	&__tabs {

		&__head {
			@include RWD(tablet) {
				position: relative;
				display: flex;
				flex-direction: column;
				padding: 20px 10px;

				&:before {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					margin-left: calc(-50vw + 50%);
					width: 100vw;
					height: 100%;
					background-color: $blue-ess;
				}
			}
		}

		&__categories {
			position: relative;
			display: flex;
			border-top-right-radius: 6px;

			@include RWD(tablet) {
				justify-content: center;
			}

			@include RWD(mobile) {
				justify-content: space-between;
			}
		}

		&__links {
			padding: 20px 25px;
			border: 1px solid #E3E3E3;
			border-radius: 6px;
			background-color: #fff;
			flex: 1;
			text-align: left;

			&.active.on {
				font-weight: bold;

				@include RWD(desktop) {
					background-color: #F1F1F1;
					border: 0;

					.vote-list__tabs__links__text {
						opacity: 1;
						color: #212121;
					}
				}

				@include RWD(tablet) {
					position: relative;

					&:before {
						content: '';
						position: absolute;
						bottom: -5px;
						height: 4px;
						width: 100%;
						background: $green-ess-gradient;
					}

					&:after {
						content: '';
						position: absolute;
						left: 0;
						right: 0;
						margin: auto;
						bottom: -20px;
						height: 11px;
						width: 17px;
						background-color: #F9FAFB;
						border-top-left-radius: 30px;
						border-top-right-radius: 30px;
						border-bottom-left-radius: 0px;
						border-bottom-right-radius: 5px;
					}
				}

			}

			@include RWD(tablet) {
				padding: 0;
				background-color: inherit;
				border: inherit;
				flex: none;
				color: #fff;
				font-size: 12px;
				margin-right: 15px;

				&:last-child {
					margin-right: 0;
				}
			}

			@include RWD(mobile) {
				padding: 0;
				background-color: inherit;
				border: inherit;
				flex: none;
				color: #fff;
				font-size: 9px;
				margin-right: 0;
			}

			&__text {
				display: flex;
				flex-direction: column;

				@include RWD(desktop) {
					opacity: 0.8;
					color: grey;
				}

				@include RWD(tablet) {
					text-align: center;
				}
			}
		}

		&__content {
			animation: fadeEffect 0.5s;

			&.active {
				display: flex;
			}
		}
	}

	&__results {
		position: relative;
		display: flex;
		background-color: #fbf7f7;
		font-size: 14px;
		font-weight: 500;
		color: #000;

		@include RWD(tablet) {
			font-size: 10px;
			background-color: inherit;
			color: #fff;
			margin-bottom: 10px;
			justify-content: left;
		}

		&__by-page {}

		&__number {
			margin-bottom: 5px;
		}
	}
}