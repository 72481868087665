.discover {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 195px;
    padding: 20px;
	border-radius: 4px;
	background-color: #F9F9F9;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.15);
    margin-top: 15px;
    margin-bottom: 15px;

    @include RWD(tablet){
        flex: 2;
    }

    @include RWD(mobile){
		flex: none;
	}

	&__title {
        margin-bottom: 15px;
	}

	&__description {
		color: $text-color;
        font-size: 13px;
        margin-bottom: 15px;
	}

	&__bubbles {
        display: flex;
        justify-content: space-between;
        padding: 0 25px;

		&__item {
			height: 35px;
            width: 35px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
			background-color: #EFEFEF;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
            
            &:before{
                content: '';
                display: inline-block;
                width: 20px;
                height: 20px;
                flex-shrink: 0;
                background-size: auto;
                background-position: center;
                background-repeat: no-repeat;
                background-image: url( svg-bg-uri($svg-vote-empty, #000000) );
            }

            &.active{
                &:before{
                    content: '';
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    flex-shrink: 0;
                    background-size: auto;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-image: url( svg-bg-uri($svg-voted, #000000) );
                }
            }
		}
	}
}