.vote {
	position: relative;
	display: flex;

	@include RWD(mobile){
		padding-top: 0;

		@at-root {
			.region-nav-tools & {
				padding-top: 0;
				margin-bottom: 0;
			}
		}
	}

	@include RWD(tablet) {
		flex-direction: column;
	}

	&__col {
		display: flex;
		flex-direction: column;

		&--left {
			@include RWD(desktop) {
				width: 25%;
				padding-top: 90px;
			}
		}

		&--right {
			@include RWD(desktop) {
				width: 55%;
				margin-left: 80px;
				padding-top: 90px;
			}
		}
	}
}

.region-nav-tools{
	&.etape-4{
		@include RWD(tablet){
			margin-bottom: 0 !important;
		}
	}
}

#main-content{
	&.etape-4{
		padding-top: 0;
		@include RWD(tablet){
			background-color: #fff;
		}
	}
}

.no-scroll{
	overflow: hidden;
}

.space-between{
	justify-content: space-between;
}