.main-title {
	color: $blue-ess;
	font-family: $main-font;
	font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    
    @include RWD(mobile){
        font-size: 12px;
    }
}