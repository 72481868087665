.vote-form {
    display: none;
    position: relative;
    max-width: 630px;
	width: 100%;
    margin: 0 auto;
    padding: 30px 50px;
    border-radius: 6px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.2);
    transform: translateY(-110px);
    z-index: 10;

    @include RWD(mobile){
        margin-top: 100px;
    }

    &.show{
        display: flex;
        justify-content: center;
        animation: puff-in-center 0.3s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;

        // IE 11
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            display: block;
        }

        .form-mentions {
            font-size: 12px;
            text-align: center;
            margin-top: 20px;
        }
    }

    @include RWD(mobile){
        max-width: 100%;
        background-color: #E8E9E8;
        padding: 20px;
        transform: none;
    }

    .flexbox{
        display: flex;
        justify-content: center;
    }

	&__formulaire {
        &__submit{
            margin-left: 10px;
        }
    }

	&__hat {
		font-size: 1.8rem;
		text-transform: uppercase;
        color: #1D3674;
        margin-bottom: 30px;
        

		@include RWD(mobile) {
			color: #212121;
            font-size: 10px;
            margin-bottom: 20px;
		}
    }
    
    &__group{
        margin-bottom: 20px;
        &:last-child{
            margin-bottom: 0px;
        }
    }

    &__wrapper{
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
    }

    // Entoure tous les champs sauf le chapeau
    .form-etape-group{
        padding: 0 50px;

        @include RWD(mobile){
            padding: 0;
        }
    }

	label {
		color: $text-color;
		font-size: 13px;

		@include RWD(mobile) {
			font-size: 10px;
		}
	}

	input {
		border: none;
		border-radius: 4px;
        background-color: #F3F4F5;
        width: 100%;
        height: 45px;
        padding-left: 10px;

        @include RWD(mobile){
            height: 35px;
        }
    }

    // Surcharge du select des communes
    .selectize-input{
        background-color: #F3F4F5 !important;
        box-shadow: none !important;
        border: none !important;
        background-image: none !important;
        height: 45px;
        display: flex;
        align-items: center;
        padding: 0;
        padding-left: 10px;
    }

    // Classe qui vise les groupes de champs checkbox

    .ezcca-edit-datatype-ezboolean{
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;

        input{
            width: 13px;
            min-width: 13px;
            height: 13px;
            border: 1px solid #979797;
            background-color: #D8D8D8;
        }
    }

    // Captcha
    .g-recaptcha{
        display: flex;
        justify-content: center;
        margin-top: 15px;
    }

    .grecaptcha-badge{
        position: relative !important;
        transition: none !important;
        right: 0 !important;
        bottom: 0 !important;
    }
    
}