/* Go from zero to full opacity */
@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes fadeOut {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

@mixin linkHover($color, $size) {
	position: relative;

	&:after {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		height: #{$size}px;
		width: 0;
		background: $color;
		transition: 0.2s;
		animation: linkHoverIntro .2s forwards;

		@keyframes linkHoverIntro {
			to {
				width: 100%;
			}
		}
	}
}

// Keyframe animation twitter heart
@keyframes heartAnim {
	from {
		background-position: left top;
	}

	to {
		background-position: right top;
	}
}

@keyframes popinIntro {
	from {
		transform: scale(0.95) translateY(-25%);
		opacity: 0;
	}
}

@keyframes slide-out-top {
	0% {
		transform: translateY(0);
		opacity: 1;
	}

	100% {
		transform: translateY(-1000px);
		opacity: 0;
	}
}

@keyframes puff-in-center {
	0% {
		transform: scale(2);
		opacity: 0;
		transform: translateY(-110px);
	}

	100% {
		transform: scale(1);
		opacity: 1;
		transform: translateY(-110px);
	}
}

@keyframes slide-in-bottom {
	0% {
		transform: translateY(1000px);
		opacity: 0;
	}

	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes slideInRight {
	from {
		opacity: 0;
		transform: translateX(-20px);
	}

	to {
		transform: none;
		opacity: 1;
	}
}

@keyframes scale-out-bottom {
	0% {
		transform: scale(1);
		transform-origin: 50% 100%;
		opacity: 1;
	}

	100% {
		transform: scale(0);
		transform-origin: 50% 100%;
		opacity: 1;
	}
}

@keyframes heartbeat {
	from {
		transform: scale(1);
		transform-origin: center center;
		animation-timing-function: ease-out
	}

	10% {
		transform: scale(.91);
		animation-timing-function: ease-in
	}

	17% {
		transform: scale(.98);
		animation-timing-function: ease-out
	}

	33% {
		transform: scale(.87);
		animation-timing-function: ease-in
	}

	45% {
		transform: scale(1);
		animation-timing-function: ease-out
	}
}

@keyframes slide-out-elliptic-top-bck {
	0% {
		transform: translateY(0) rotateX(0) scale(1);
		transform-origin: 50% 1400px;
		opacity: 1
	}

	100% {
		transform: translateY(-600px) rotateX(-30deg) scale(0);
		transform-origin: 50% 100%;
		opacity: 1
	}
}

.vote-cards__category__wrap {
	position: absolute;
	overflow: hidden;
	left: 0;
	right: 0;
	width: 100%;
	display: flex;
	transform: translateY(20px);
	transition: all 0.5s;
	visibility: hidden;
}

.vote-cards__category__wrap .vote-cards__category__box {
	opacity: 0;
}

.vote-cards__category__wrap:before {
	position: absolute;
	width: 1px;
	height: 1px;
	background: white;
	content: "";
	bottom: 10px;
	left: 50%;
	top: 95%;
	color: #fff;
	border-radius: 50%;
	-webkit-transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
	transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.vote-cards__category__wrap.active {
	display: block;
	visibility: visible;
	box-shadow: 2px 3px 16px silver;
	transition: all 600ms;
	transform: translateY(0px);
	transition: all 0.5s;
}

.vote-cards__category__wrap.active:before {
	height: 2000px;
	width: 2000px;
	border-radius: 50%;
	top: 50%;
	left: 50%;
	margin-left: -1000px;
	margin-top: -1000px;
	display: block;
	-webkit-transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
	transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.vote-cards__category__wrap.active .vote-cards__category__box {
	position: relative;
	z-index: 1;
	opacity: 1;
	transition: all 600ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}