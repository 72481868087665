.pagination {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	padding: 5px 15px;
	margin: 0 60px;
	margin-bottom: 30px;
	background: $green-ess-gradient;
	font-size: 1.8rem;
	font-weight: 500;
	border-radius: 5px;

	@include RWD(mobile) {
		background: inherit;
		margin: 0 50px;
		padding: 5px 0;
		margin-bottom: 30px;
	}

	a {
		text-decoration: none;
		color: #000000;
		line-height: 1.2em;

		&:hover,
		&:focus {
			@include linkHover(#000000, 2);
		}
	}

	button {
		font-size: 1.8rem;
		font-weight: 500;
		line-height: 1.2em;
		color: #000000;

		&:hover,
		&:focus {
			@include linkHover(#000000, 2);
		}
	}

	//BUTTONS

	&__start {
		@include RWD(mobile) {
			display: none !important;
		}
	}

	&__end {
		@include RWD(mobile) {
			display: none !important;
		}
	}

	&__prev {
		margin-left: 20px;

		@include RWD(mobile) {
			margin-left: 0;

			display: flex;
			justify-content: center;
			padding: 8px 15px;
			border-radius: 15px;
			background-color: #F4F5F6;
			text-decoration: none;

			font-family: $main-font;
			font-size: 1rem;
			font-weight: 500;
			color: #212121;
			line-height: 1em;
		}
	}

	&__next {
		margin-right: 20px;

		@include RWD(mobile) {
			margin-right: 0;

			display: flex;
			justify-content: center;
			padding: 8px 15px;
			border-radius: 15px;
			background-color: #F4F5F6;
			text-decoration: none;

			font-family: $main-font;
			font-size: 1rem;
			font-weight: 500;
			color: #212121;
			line-height: 1em;
		}
	}

	//LINKS

	&__pages {
		@include RWD(mobile) {
			display: none;
		}
	}

	&__number {
		margin-right: 10px;

		&.active {
			color: red;
		}

		&:last-child {
			margin-right: 0;
		}
	}
}