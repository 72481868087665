%atom-form-radios {
  & + label {
    &:before {
      min-width: 15px;
    }
  }
}

.form-item {
  & > label {
    &:before {
      min-width: 15px;
    }
  }
}

.webform-client-form input[type='radio'] + label:before {
  min-width: 15px;
}
