.post-vote {
    display: none;
    justify-content: center;
    align-items: center;
	z-index: 100;
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	background: rgba($color: #000, $alpha: 0.7);

	&.open{
		display: flex;
		animation: fadeIn 1s forwards;
	}

	&__box {
		z-index: 2;
		display: flex;
		flex-direction: column;
		position: relative;
		width: 100%;
		max-width: 500px;
        padding-left: 45px;
        padding-right: 45px;
        padding-top: 20px;
        padding-bottom: 35px;
		background-color: $blue-ess;
		border-radius: 10px;
		animation: popinIntro 0.5s;

		@keyframes popinIntro {
			from {
				transform: scale(0.95) translateY(-25%);
				opacity: 0;
			}
		}

		@include RWD(tablet) {
			height: auto;
		}

		@include RWD(mobile) {
            max-width: 80%;
            padding: 20px 30px;
			flex-direction: column;
		}
	}

	//Actions

	&__close {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		right: 10px;
		top: 10px;
		width: 15px;
		height: 15px;
		background-color: #fff;
		border-radius: 50%;
        box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.2);
        
		&:before {
			content: '';
			display: inline-block;
			width: 7px;
			height: 7px;
			flex-shrink: 0;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url(svg-bg-uri($svg-close, $blue-ess));
		}
    }

    &__surtitre{
        font-size: 18px;
        line-height: 1.2em;
        text-transform: uppercase;
        color: #fff;
        text-align: center;
    }

    &__title{
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        color: #fff;
    }

    &__link{
		margin-left: 10px;
		margin-right: 10px;

        @include RWD(mobile){
            margin-bottom: 10px;

            &:last-child{
                margin-bottom: 0;
            }
        }

        &--vote{
			align-self: center;
			margin-top: 15px;
		}
		
		&.selected{
			display: none;
		}

		&.check{
			pointer-events: none;
			&:before{
				content: '';
				position: absolute;
				top: -4px;
				right: -5px;
				display: inline-block;
				width: 15px;
				height: 15px;
				flex-shrink: 0;
				background-size: 7px;
				background-position: center;
				background-repeat: no-repeat;
				background-image: url( svg-bg-uri($svg-check, $blue-ess) );
				background-color: #fff;
				border-radius: 50%;
				box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.2);
			}
		}
    }
    
	&__col {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
    }
    
    .flexbox{
        display: flex;
        justify-content: center;

        @include RWD(mobile){
            flex-direction: column;
            align-self: center;
        }
    }
}