.vote-state {
	display: flex;
	flex-direction: column;
	position: relative;
	padding: 20px;
	border-radius: 6px;
	background-color: #F9F9F9;
	box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.15);
	margin-top: 15px;
	margin-bottom: 15px;

	@include RWD(tablet){
		margin-bottom: 40px;
	}

	&__title {
		margin-bottom: 20px;
	}

	&__progress {
		display: flex;
		flex-direction: column;

		@include RWD(tablet) {
			flex-direction: row;
		}

		&__item {
			position: relative;
			display: flex;
			padding-bottom: 20px;
			z-index: 2;

			@include RWD(tablet) {
				flex-direction: column;
				align-items: center;
				flex: 1;
			}

			&__state {
				display: flex;
				justify-content: center;
				height: 12px;
				width: 12px;
				min-width: 12px;
				border-radius: 50%;
				background-color: #FFFFFF;

				&.active{
					border: 3px solid $green-ess;
				}

				&.selected{
					background-color: $green-ess;

					&:before{
						content: '';
						position: absolute;
						top: 3px;
						left: 3px;
						display: inline-block;
						width: 5px;
						height: 5px;
						flex-shrink: 0;
						background-size: contain;
						background-position: center;
						background-repeat: no-repeat;
						background-image: url( svg-bg-uri($svg-check, #ffffff) );
					}
				}

				@include RWD(tablet) {
                    width: 20px;
                    height: 20px;
                    align-items: center;
                    background-color: #D8D8D8;

                    &:before{
                        content: '';
                        position: absolute;
                        width: 7px;
                        height: 7px;
                        border-radius: 50%;
                        background-color: #FFFFFF;
                    }
				}

				&:after {
					content: '';
					position: absolute;
					width: 2px;
					height: 100%;
					background-color: #FFFFFF;
					box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
					z-index: -1;

					@include RWD(tablet) {
						height: 3px;
                        width: 100%;
                        background-color: #D8D8D8;
					}
				}
			}

			//Remove le trait de la barre de progression pour le dernier item
			@include RWD(desktop) {
				&:last-child {
					.vote-state__progress__item__state {
						&:after {
							display: none;
						}
					}
				}
			}

			//Remove les traits de la barre de progression pour les derniers items responsive
			@include RWD(tablet) {
				&:last-child {
					.vote-state__progress__item__state {
						&:after {
							left: 0;
							width: 50%;
						}
					}
				}

				&:first-child {
					.vote-state__progress__item__state {
						&:after {
							right: 0;
							width: 50%;
						}
					}
				}
			}

			&__text {
				display: flex;
				flex-direction: column;
				margin-left: 10px;

				@include RWD(tablet) {
                    margin-left: 0;
                    margin-top: 5px;
                    text-align: center;
				}
			}

			&__categorie {
				font-family: $main-font;
				color: $text-color;
				font-size: 13px;
				margin-bottom: 5px;

				@include RWD(tablet) {
					font-size: 10px;
				}
			}

			&__info {
				font-family: $main-font;
				color: #817E7E;
				font-size: 11px;
				font-weight: 500;

				@include RWD(tablet) {
					display: none;
				}
			}
		}
	}
}