/*------------------------------------*\
    			Variables
\*------------------------------------*/

$break-small: 767;
$break-large: 1200;

/*------------------------------------*\
				$FONTS
\*------------------------------------*/

$main-font: 'roboto';

/*------------------------------------*\
    			$COULEURS
\*------------------------------------*/

$text-color: #2C2C2C;
$blue-ess: #192A5E;
$green-ess:#61F09D;
$green-ess-gradient: linear-gradient(206.57deg, #AFF57A 0%, #46EEAA 100%);
$blue-ess-gradient: linear-gradient(206.57deg, #7AF5CB 0%, #46E0EE 100%);
$orange-ess-gradient: linear-gradient(206.57deg, #FFC900 0%, #FFB40B 100%);