.share-this {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: 2;

	&__item {
		animation: shareIn 0.5s;

		@keyframes shareIn {
			from {
				transform: scale(1.05) translateY(5px);
				opacity: 0;
				box-shadow: 0 5px 10px rgba($color: #000, $alpha: 0.2);
			}
		}

		&--toggle {
			background-image: url(svg-bg-uri($svg-share, #fff));

			&.active {
				background-image: url(svg-bg-uri($svg-close, #fff));
				animation: shareToggleIconIntro 0.5s;

				@keyframes shareToggleIconIntro {
					from {
						transform: rotate(-45deg);
					}
				}
			}
		}

		&--print {
			background-image: url(svg-bg-uri($svg-print, #fff));
		}

		&--facebook {
			background-image: url(svg-bg-uri($svg-facebook, #fff));
		}

		&--twitter {
			background-image: url(svg-bg-uri($svg-touit, #fff));
		}

		&:last-child {
			margin-right: 0;
		}
	}

	&__content {
		position: absolute;
		bottom: 0;
		display: none;
		flex-direction: column;
		transform: translateY(100%) translateY(5px);

		&.active {
			display: flex !important;
		}
	}
}