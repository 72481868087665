.panier-col{
    @include RWD(desktop){
        position: relative;
        width: 100px;
        display: flex;
        justify-content: center;
    }
}

.panier-btn {
    position: fixed;
    top: 300px;
    display: none;
	height: 60px;
    width: 60px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
	background: $green-ess-gradient;
    box-shadow: 0 3px 4px 0 rgba(103,103,103,0.5);

    &.show{
        display: flex;
    }

    @include RWD(tablet){
        position: fixed;
        top: inherit;
        bottom: 20px;
        right: 20px;
        z-index: 20;
    }
    
    &:before{
        content: '';
        display: inline-block;
        width: 35px;
        height: 35px;
        flex-shrink: 0;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url( svg-bg-uri($svg-vote, #000000) );
    }
}