@include RWD(mobile) {

	#blockSteps {
        .block-wrapper{
            padding-top: 0;
        }

        .block-title{
            margin-top: 30px;
            margin-bottom: 50px;
            line-height: 1.2em !important;
        }

		.step {
			display: none !important;
			padding-left: 0 !important;
			text-align: center;

			&:before {
                left: 0 !important;
                right: 0 !important;
                margin: auto !important;
			}

			&.active {
				display: flex !important;
				flex-direction: column;
			}

			&-state {
				display: none !important;
			}

			&-date {
                padding: 0 !important;
                margin-top: 15px;
			}

			&-title {
				&:after {
					margin-left: auto;
					margin-right: auto;
				}
            }
            
            &-picto{
                right: 50px !important;
            }

            &-number{
                margin-bottom: 30px;
            }
		}
	}
}