.panier {
	display: none;
	flex-direction: column;
	justify-content: center;
	max-width: 630px;
	width: 100%;
	height: 100%;
	min-height: 275px;
	margin: 0 auto;
	border-radius: 6px;
	background-color: #FFFFFF;
	box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.2);
	padding: 20px 50px;
	transform: translateY(-110px);
	z-index: 10;

	@include RWD(mobile) {
		max-width: 100%;
		transform: none;
	}

	&.show {
		display: flex;
		animation: puff-in-center 0.3s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;

		@include RWD(mobile) {
			animation: none;
		}
	}

	@include RWD(mobile) {
		padding: 20px;
		background-color: #E8E9E8;
		margin-bottom: 50px;
		box-shadow: none;
	}

	&__title {
		opacity: 0;
		// Appel de l'animation slideInRight
		animation: slideInRight 1s forwards;
		animation-delay: 0.5s;

		@include RWD(mobile) {
			color: #212121;
		}
	}

	&__list {
		padding-left: 10px;

		@include RWD(mobile) {
			padding-left: 0;
		}
	}

	&__picto {
		height: 35px;
		width: 35px;
		margin-right: 25px;
		background-color: #D8D8D8;
		border-radius: 50%;
		display: inline-block;
		flex-shrink: 0;
		background-size: auto;
		background-position: center;
		background-repeat: no-repeat;
		background-image: url(svg-bg-uri($svg-voted, #000000));

		@include RWD(mobile) {
			margin-right: 20px;
		}
	}

	&__item {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 1.3rem;
		color: $text-color;
		margin-top: 20px;
		opacity: 0;
		z-index: 2;

		@include RWD(mobile) {
			position: relative;
			z-index: 1;
			padding-bottom: 20px;

			&:nth-child(1) {
				z-index: 3;
			}

			&:nth-child(2) {
				z-index: 2;
			}

			&:after {
				content: '';
				position: absolute;
				bottom: 0;
				height: 3px;
				width: 100%;
				border-radius: 1.5px;
				background-color: #D8D8D8;
			}
		}

		// Appel de l'animation slideInRight
		animation: slideInRight 1s forwards;

		@for $i from 1 through 3 {
			&:nth-child(#{$i}) {
				animation-delay: #{($i + 7) * 0.1s}
			}
		}

		&__content {
			display: flex;
			align-items: center;
		}

		&__text {
			flex: 1;
			z-index: 2;
			@include RWD(mobile) {
				display: flex;
				flex-direction: column;
			}
		}

		&__name {}

		&__category {}

		&__action {
			display: none;
			position: absolute;
			top: 0;
			right: 0;
			transform: translateX(165px);
			flex-direction: column;
			padding: 25px;
			background-color: #fff;
			border-radius: 6px;
			box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.2);
			z-index: 10;

			@include RWD(tablet){
				top: 55px;
				transform: translate(-40px, -70px);
			}

			&__modify {
				margin-bottom: 15px;
			}

			&__remove {}

			&.open {
				display: flex;
			}
		}

		&__more {
			position: relative;
			height: 35px;
			width: 35px;
			min-width: 35px;
			border-radius: 50%;
			background: $green-ess-gradient;

			&:after {
				content: '•••';
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%) rotate(90deg);
				font-size: 19px;
				color: #2a2a2a;
			}
		}
	}

	&__btn {
		opacity: 0;
		// Appel de l'animation slideInRight
		animation: slideInRight 1s forwards;
		animation-delay: 0.5s;

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			opacity: 1;
			animation: none;
		}

		margin-top: 20px;
		align-self: center;
	}
}