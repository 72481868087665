.tuto {

	&__col {
		display: flex;
	}

	&__content {
		display: none;

		&.show {
			display: block;
		}
	}

	&__portrait {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 65px;
		width: 65px;
		min-width: 65px;
		border-radius: 50%;
		background-color: #fdf0cd;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			min-width: 50px;
			width: 50px;
			height: 50px;
			background-color: #F7B500;
			border-radius: 50%;
		}

		&--small {
			min-width: 45px;
			width: 45px;
			height: 45px;
			margin-right: 15px;

			&:before {
				min-width: 30px;
				width: 30px;
				height: 30px;
			}
		}
	}

	&__picto {
		position: relative;
		width: 30px;
		height: 30px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;

		&--small {
			width: 20px;
			height: 20px;
		}
	}

	&__box {
		width: 100%;
		border-radius: 5px;
		background-color: #F3F4F5;
		box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.15);
		font-size: 12px;
		line-height: 14px;
		font-weight: 500;
		padding: 10px;
		margin-left: 10px;

		&__btn {
			color: #7321C6;
			font-weight: 400;
		}
	}

	&__window {
		display: none;
		position: absolute;
		right: 0;
		top: 0;
		min-height: 130px;
		width: 235px;
		border-radius: 5px;
		background-color: #FFFFFF;
		box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.15);
		padding: 15px;
		z-index: 20;

		@media (min-width: 1400px) {
			margin-right: -235px;
		}

		@include RWD(tablet) {
			margin-right: 0;
		}

		&.active {
			display: block;
		}

		&__close {
			position: absolute;
			right: 10px;
			top: 10px;
			display: inline-block;
			width: 10px;
			height: 10px;
			flex-shrink: 0;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url(svg-bg-uri($svg-close, #000000));

			@media (max-width: 1400px) {
				right: 15px;
				top: 12px;
			}
		}

		&__btn {
			font-size: 11px;
			font-weight: 500;
			line-height: 14px;
			color: #0E65E5;

			&__wrapper {
				margin-top: 15px;
			}
		}

		&__text {
			display: block;
		}

		&__title {
			width: 100%;
			font-size: 11px;
			font-weight: 500;
			text-transform: uppercase;
			color: #000000;
			line-height: 14px;
		}

		&__description {
			width: 100%;
			font-size: 11px;
			font-weight: 500;
			line-height: 14px;
			color: #000000;
			margin-top: 5px;
		}
	}

	&__step {
		position: absolute;
		right: -10px;
		top: -10px;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 25px;
		width: 25px;
		min-width: 25px;
		min-height: 25px;
		border-radius: 50%;
		background-color: #fdf0cd;
		z-index: 21;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			min-width: 15px;
			width: 15px;
			height: 15px;
			background-color: #F7B500;
			border-radius: 50%;
		}

		&.visited {
			background-color: #cedef9;

			&:before {
				background-color: #0E65E5;
			}
		}

		&.active {
			animation: heartbeat 1.7s ease-in-out infinite both
		}
	}
}